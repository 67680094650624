import { defineStore } from 'pinia'
import { initializeApp } from 'firebase/app'
import {
  initializeAppCheck,
  getToken,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check'

let recaptchaKey = import.meta.env.VITE_RECAPTCHA_KEY

export const useAppStore = defineStore({
  id: 'app',

  state: () => ({
    appCheck: null,
    fireApp: null,
    provider: null,
    title: null,
    formResponse: null,
  }),

  getters: {
    // Appcheck Token
    async token() {
      if (recaptchaKey == 'none') return 'none'

      let token = await getToken(this.appCheck, true)
      // console.log('appCheck Token: ', token)
      return token.token
    },

    // Features
    hasSigninWithApple() {
      return false
    },
  },

  actions: {
    initialize() {
      if (this.fireApp) return
      if (recaptchaKey == 'none') return
      // only init once!

      const appConfig = JSON.parse(import.meta.env.VITE_APP_FIREBASE)
      this.fireApp = initializeApp(appConfig)
      let provider = new ReCaptchaEnterpriseProvider(recaptchaKey)
      this.provider = provider

      let appcheckConfig = {
        provider: provider,
        isTokenAutoRefreshEnabled: true,
      }

      try {
        this.appCheck = initializeAppCheck(this.fireApp, appcheckConfig)
      } catch (err) {
        console.log(err)
      }
    },
  },
})
